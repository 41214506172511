<template>
    <div class="text-center my-3">
        <button type="button" class="btn btn-primary" @click="sendEmail" :disabled="disableSend">
            Resend <span v-if="disableSend"> (wait {{ resendCount }} sec) </span>
        </button>
        <div class="alert alert-success mt-4" v-if="sentEmail">
            We have sent you an email. Please, check your inbox.
        </div>
    </div>
</template>

<script>
/* eslint-disable */
const SEND_INTERVAL_SEC = 60

export default {
    name: 'ResendEmail',
    props: {
        email: {
            type: String,
            default: ''
        },
        user_id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            sentEmail: false,
            resendCount: SEND_INTERVAL_SEC,
            countInterval: null,
            disableSend: false
        }
    },
    methods: {
        sendEmail() {            
            this.$store.dispatch('auth/sendVerify', {email: this.email, user_id: this.user_id})
            .then(() => {
                this.sentEmail = true
                this.disableSend = true
                this.startCountDown()
            })
        },
        startCountDown() {
            this.countInterval = setInterval(() => {
                this.resendCount--
                if (this.resendCount <= 0) {
                    clearInterval(this.countInterval)
                    this.resendCount = SEND_INTERVAL_SEC
                    this.disableSend = false
                    this.sentEmail = false
                }
            }, 1000)
        }
    }
}
</script>