/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueYandexMetrika from 'vue3-yandex-metrika'
import 'bootstrap/dist/css/bootstrap.min.css'
// import './assets/app.scss'
import axios from "axios"
// import bootstrap from 'bootstrap'
import 'bootstrap/js/dist/collapse'
import VueGtag from "vue-gtag";

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://localhost:8001/api/v1/'
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER+'api/v1/'
//TODO interceptors for 401 error


const setRouteRedirect = function(currPath) {
    const routeRedirect = { 
        path: '/login'         
    }
    if (currPath !== '/') {
        routeRedirect.query = { returnUrl: currPath }
    }
    return routeRedirect
}

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status === 403) {
        router.push({ name: 'RestrictedArea' })
    }
    if (error.response.status === 401) {
        const currPath = router.currentRoute.value.path
        if (currPath === '/login') {
            return
        }
        store.commit('auth/LOGOUT_SUCCESS')
        router.push(setRouteRedirect(currPath))
    }
    return Promise.reject(error);
  });

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueYandexMetrika, {
	id: '87008046',
	router: router,
	env: process.env.NODE_ENV,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true,
    },
    // debug: true,
})

app.use(VueGtag, {
    config: { id: "G-FWWV45H6EK" }    
}, router);
app.mount('#app')

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['login', 'register', 'VerifyEmail', 'ForgotPassword', 'ResetPassword'];
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = store.state.auth.userEmail
    
    store.dispatch('balance_current/init')
    
    if (authRequired && !loggedIn) {  
        return next(setRouteRedirect(to.path))
    }
    next();
})