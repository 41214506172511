/* eslint-disable */
import axios from 'axios'

export default {
    namespaced: true,
    state: {
      userEmail: localStorage.getItem('userEmail'),
      groupName: null,
      ownerEmail: null,
    },
    mutations: {
      LOGIN_SUCCESS (state, payload) {
        localStorage.setItem('userEmail', payload.email)
        state.userEmail = payload.email
      },
      LOGOUT_SUCCESS (state, payload) {
        localStorage.removeItem('userEmail')
        state.userEmail = null
      },
    },
    actions: {
      LOGIN: ({ commit }, payload) => {
        // let a = {'b': 1}
        // console.log(a.b.d.c);
        return new Promise((resolve, reject) => {            
            axios
            .post(`accounts/login/`, payload)
            .then(({ data, status }) => {
              if (status === 200) {
                commit('LOGIN_SUCCESS', {email: payload.login})
                resolve(true);
              }
            })
            .catch(error => {
                let errorResp
                const detail = error.response?.data?.detail
                if (detail) {
                  errorResp = detail
                } else {
                    errorResp = error.response?.data;
                }
              reject(errorResp);
            });
        });
      },
      LOGOUT: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
            .post(`accounts/logout/`, payload)
            .then(({ data, status }) => {
              if (status === 200) {
                commit('LOGOUT_SUCCESS')
                resolve(true);
              }
            })
            .catch(error => {
              commit('LOGOUT_SUCCESS')
              reject(error);
            });
        });
      },
      REGISTER: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
            .post(`accounts/register/`, payload)
            .then(({ data, status }) => {
              if (status === 201) {
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      VERIFY: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
            .post(`accounts/verify-registration/`, payload)
            .then(({ data, status }) => {
              if (status === 200) {
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      SEND_RESET_PASSWORD_LINK: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
            .post('accounts/send-reset-password-link/', payload)
            .then(({ data, status }) => {
              if (status === 200) {
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      RESET_PASSWORD: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
            .post('accounts/reset-password/', payload)
            .then(({ data, status }) => {
              if (status === 200) {
                resolve(true);
              }
            })
            .catch(error => {
              let errorResp
              const detail = error.response?.data?.detail
              if (detail) {
                errorResp = detail
              } else {
                  errorResp = error.response?.data;
              }
              reject(errorResp);
            });
        });
      },
      VERIFY_CHANGE_EMAIL: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            axios
            .post(`accounts/verify-email/`, payload)
            .then(({ data, status }) => {
              if (status === 200) {
                resolve(true);
              }
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      getInvite({commit, state}, {uid}) {
        return axios.get(`user_invitations/${uid}`)        
      },
      changePassword: ({ commit }, payload) => {
        // console.log('CHANGE PASSWORD')
        return axios.post('accounts/change-password/', payload)
      },
      changeEmail: ({ commit }, payload) => {
        // console.log('CHANGE EMAIl')
        return axios.post('accounts/register-email/', payload)
      },
      sendVerify: ({ commit }, payload) => {
        // console.log('CHANGE EMAIl')
        return axios.post('resend_verify_link/', payload)
      },
      loadCommonUserData({commit, state}) {
        return axios.get('/users/group_name/')
          .then(resp => {
              state.groupName = resp.data?.group_name || null
              state.ownerEmail = resp.data?.ownerEmail || null
          })
      },
      removeTeamAccount: ({ commit }, payload) => {        
        return axios.post('remove_tenant/', payload)
      },
      removeAccount: ({ commit }, payload) => {        
        return axios.post('users/destroy_user/', payload)
      },
    },    
  }  