<template>
    <nav>
      <ul class="pagination">
        <li class="page-item" :class="{'disabled': page === 1}" @click="changePage(this.page-1)">
            <a class="page-link" href="#">Previous</a>
        </li>
        <li class="page-item disabled" v-if="needStartSeparator">
            <span class="page-link">…</span>
        </li>
        <li class="page-item"
            :class="{'active' : page === pageNum}"
            v-for="(pageNum, index) in pages"
            :key="index+pageNum"
            @click="changePage(pageNum)"
        >
            <a class="page-link" href="#">{{ pageNum }}</a>
        </li>
        <li class="page-item disabled" v-if="needEndSeparator">
            <span class="page-link">…</span>
        </li>
        <li class="page-item" :class="{'disabled': !haveNextPage}" @click="changePage(this.page+1)">
            <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        page: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 10
        },
        totalRows: {
            type: Number,
            required: true
        }
    },
    emits: ['update:page'],
    computed: {
        totalPages() {
            return Math.ceil(this.totalRows / this.perPage)
        },
        haveNextPage() {
            return this.totalPages > this.page
        },
        pages() {
            const page = this.page
            const pages = [page]
            if (page === 1) {
                if (this.totalPages > page) {
                    pages.push(this.page + 1)
                }
                if (this.totalPages > page + 1) {
                    pages.push(this.page + 2)
                }
            } else if (page === this.totalPages) {
                if (page > 1) {
                    pages.unshift(page - 1)
                }
                if (page > 2) {
                    pages.unshift(page - 2)
                }
            } else {
                if (page > 1) {
                    pages.unshift(page - 1)
                }
                if (this.totalPages > page) {
                    pages.push(this.page + 1)
                }
            }
            return pages
        },
        needStartSeparator() {
            return Math.min(...this.pages) > 1
        },
        needEndSeparator() {
            return Math.max(...this.pages) < this.totalPages
        }
    },
    methods: {
        changePage(pageNum) {
            let page = pageNum
            if (page > this.totalPages) {
                page = this.totalPages
            }
            if (page < 1) {
                page = 1
            }
            if (this.page !== page) {
                this.$emit('update:page', page)
            }
        }
    }
}
</script>
