<template>
    <div>
        <button class="btn btn-success btn-sm" @click="openModal()">
            Upload List
        </button>
        <div class="modal fade" tabindex="-1" id="listUploadModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Select file for uploading</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            Select a .TXT or .CSV file containing your emails.
                            <br>
                            TIP: The order of emails at the report will be the same as your uploaded list.
                        </div>
                        <div>
                            Docs: How to
                            <a target="_blank" :href="`${docServer}docs/bulk-validation/#uploading-files`">create appropriate files</a>
                            for uploading?
                        </div>
                        <!-- <form @submit.prevent="uploadFile(file)"> -->
                        <form @submit.prevent="addList(file)">
                            <div class="my-3">
                                <input
                                    class="form-control"
                                    required
                                    type="file"
                                    ref="file"
                                    @change="file = $refs.file.files[0]"
                                    accept=".csv, .xlsx, .txt"
                                    id="formFile"
                                >
                            </div>
                            <div class="mb-3 text-muted">
                                Maximum filesize is 50 Mb.
                                <br>
                                Maximum allowed emails per file: 100K.
                            </div>
                            <div class="mb-3">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                        <div v-if="uploadError" class="alert alert-danger">
                            {{ uploadError }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Modal from 'bootstrap/js/dist/modal'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('bulkVal')

export default {
    name: 'ListUploader',
    data() {
        return {
            file: null,
            modal: null            
        }
    },
    computed: {
        ...mapState(['uploadError']),
        docServer() {
            return process.env.VUE_APP_DOC_SERVER
        }
    },
    methods: {
         ...mapActions(['uploadFile']),
        openModal() {
            this.modal = new Modal(document.getElementById('listUploadModal'));
            this.modal.show()
        },
        addList(file) {            
            this.uploadFile(file)
            .then(() => {
                this.modal.hide()
                this.$emit('upload')
            })                  
        }
    }
}
</script>
