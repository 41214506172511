<template>
    <div>
       <div class="card me-3" style="max-width: 800px">
           <div class="card-body d-flex flex-wrap justify-content-between">
               <div>
                    <div class="fw-bold">
                        {{ item.name }}
                    </div>
                    <div>
                        Key: {{ item.key}}
                    </div>
                    <div>
                        Key owner: {{ item.user.email }}
                    </div>
                </div>
                <div class="d-flex">
                    <div style="font-size: 80%">
                        {{ (new Date(item.created)).toLocaleString() }}
                    </div>
                    <BIconTrash class="text-danger ms-3"  @click="$emit('keyDelete', item)" />
                </div>
           </div>
       </div>
    </div>
</template>

<script>
/* eslint-disable */
import { BIconTrash } from 'bootstrap-icons-vue'


export default {
    components: {
        BIconTrash
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>