<template>
    <div>
       <h4 class="mb-3">API Keys</h4>
       <div class="my-4">
           <button class="btn btn-sm btn-success" @click="openCreateModal">Add API key</button>
       </div>
       <ApiKey v-for="item in items" :key="item.id" :item="item"  @keyDelete="showConfirmKeyDelete(item)"/>
       <div class="modal fade" id="confirmKeyDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Are you sure to remove this key?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        This action is irreversible.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteKey({key: this.keyToDelete})">Yes, remove</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" tabindex="-1" id="keyCreateModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Create API key</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            Each key must have individual name
                        </div>
                        <form @submit.prevent="addKey(keyName)">
                            <div class="my-3">
                                <label for="nameInput" class="form-label">Name</label>
                                <input v-model="keyName" class="form-control" id="nameInput" required>
                            </div>
                            <div class="mb-3">
                                <button type="submit" class="btn btn-primary">Create key</button>
                            </div>
                        </form>
                        <div v-if="createError" class="alert alert-danger">
                            {{ createError }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import ApiKey from '@/components/ApiKey.vue'
import Pagination from '@/components/Pagination.vue'
import Modal from 'bootstrap/js/dist/modal'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('keys')

export default {
    components: {
        ApiKey,
        Pagination
    },
    data() {
        return {
            keyToDelete: null,
            createModal: null,
            keyName: ''
        }
    },
    computed: {
        ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page', 'createError']),
    },
    methods: {
        ...mapActions(['init', 'changePage', 'loadList', 'changeQuery', 'deleteKey', 'createKey']),
        showConfirmKeyDelete(key) {
            this.keyToDelete = key;
            (new Modal( document.getElementById('confirmKeyDelete'))).show()
        },
        openCreateModal() {
            this.createModal = new Modal(document.getElementById('keyCreateModal'));
            this.createModal.show()
        },
        addKey(name) {
            this.createKey({name})
            .then(() => {
                this.createModal.hide()
                this.keyName = ''
                this.loadList()
            })                  
        }
    },
     mounted() {
      this.init()
    }
}
</script>