/* eslint-disable */
import axios from 'axios'

const getDefaultState = () => {
  return {
    items: [],
    totalRows: 0,
    error: null,
    isLoading: false,
    page: 1,
    result: '',
    query: null,
    uploadError: null,
    with_deleted: ''
  }
}
const state = getDefaultState()

export default {
    namespaced: true,    
    state,
    mutations: {
      SET_PAGE (state, {page}) {
        state.page = page
      },
      SET_QUERY (state, {query}) {
        state.query = query
      },
      INIT (state) {
        Object.assign(state, getDefaultState())        
      },
      REQUEST_LIST (state) {        
        state.isLoading = true
      },
      RECEIVE_LIST_ERROR (state, {error}) {
        state.isLoading = false
        state.error = error
      },
      RECEIVE_LIST_SUCCESS (state, {items, totalRows}) {
        state.isLoading = false
        state.items = items
        state.totalRows = totalRows
      },
    },
    actions: {
      loadList: ({commit, state}) => {
        commit('REQUEST_LIST')
        // setTimeout(() => {
          axios.get(`validation_lists/?with_deleted=${state.with_deleted}&page=${state.page}`, {params: state.query})
          .then(({ data, status }) => {
            if (status === 200) {
              commit('RECEIVE_LIST_SUCCESS', {items: data.results, totalRows: data.count})
            }
          })
          .catch(error => {
            commit('RECEIVE_LIST_ERROR', {error: error.response.data})
          });
        // }, 0)
      },
      changePage: ({commit, dispatch}, page) => {
        commit('SET_PAGE', {page})
        dispatch('loadList')
      },
      changeQuery: ({commit, dispatch}, query) => {
        commit('SET_QUERY', {query})
        commit('SET_PAGE', {page: 1})
        dispatch('loadList')
      },
      init: ({commit, dispatch, state}, with_deleted = false) => {
        commit('INIT')
        if (with_deleted) {
          state.with_deleted = '1'
        }
        dispatch('loadList')
      },
      deleteList({commit, dispatch, state}, {list}) {        
        // console.log('DELETE LIST')
        
        axios.post(`validation_lists/${list.id}/mark_deleted/`)
        .then( ({data, status}) => {
          state.items = state.items.filter(item => item.id !== list.id)
          dispatch('loadList')
        })
      },
      uploadFile({commit, dispatch, state}, file) {
        const formData = new FormData()
        let blob = file.slice(0, file.size, file.type);
        let newFile = new File([blob], "filename", {type: file.type});                    
        formData.append("filename", file.name);
        formData.append('file', newFile)
        state.uploadError = null
        return axios.post(`validation_lists/`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          }
        })
        .then( ({data, status}) => {
          dispatch('loadList')
          // state.items = state.items.filter(item => item.id !== list.id)
        })
        .catch(error => {          
          state.uploadError = error.response.data
          throw error
        })
      },
      startVal({commit, dispatch, state}, {list}) {
        // console.log('startValstartVal', list)
        return axios.post(`validation_lists/${list.id}/start_validation/`)
      },
      abortVal({commit, dispatch, state}, {list}) {
        // console.log('startValstartVal', list)
        return axios.post(`validation_lists/${list.id}/abort_validation/`)
      }
    }
  }  