<template>
    <div>
       <h3>Team Members</h3>
       <h6 class="mb-4">
           Invite team members to your ccount. You can assign a role for each user for different access levels.
       </h6>
       <!-- <h6 class="mb-4">
           You can have up to {{ $options.MAX_USERS }} team members.
       </h6> -->
       <button class="btn btn-success btn-sm mb-4" @click="showInviteModal">Add Member</button>
       <!-- <div class="text-end">
            <b>{{ items.length }} </b> of <b> {{ $options.MAX_USERS }} </b> Team Members Used
       </div> -->
       <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div>
        <div v-if="errorInvite" class="alert alert-danger">
            The invitation has not sent.
        </div>
        <div v-if="inviteSent" class="alert alert-success fw-bold mt-2">
            The invitation has sent.
        </div>
       <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Invited</th>
                    <th>Registered</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody :class="{'opacity-25' : isLoading}">
                <RowManageUsers
                    v-for="(user, index) in items"
                    :key="index"
                    :user="user"
                    @userDelete="showConfirmUserDelete(user)"
                    @inviteSent="handleInviteSent"
                    @inviteError="handleInviteError"
                />
                </tbody>
            </table>
        </div>
        <Pagination :totalRows="totalRows" :page="page" :perPage="10" @update:page="changePage($event)" />
        <div class="modal fade" id="confirmUserDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Are you sure to remove the team member?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        This action is irreversible.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteUser({user: this.userToDelete})">Yes, remove</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="userInviteModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <h5 class="mb-3">Send Invite to a New Member</h5>
                        <form @submit.prevent="inviteMember(invitation); invitation.email = null; invitation.role = null">
                            <div class="mb-3">
                                <label for="emailInput" class="form-label">Email</label>
                                <input v-model.trim="invitation.email" id="emailInput" type="email" required class="form-control">
                            </div>
                            <div class="mb-3">
                                <label for="roleInput" class="form-label">Role</label>
                                <select class="form-select" id="roleInput" required v-model="invitation.role">
                                    <option :value="group.id" v-for="group in groupsWithoutOwner" :key="group.id">
                                        {{ group.name }}
                                    </option>
                                </select>
                            </div>
                            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Send</button>
                            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Cancel</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination.vue'
import { createNamespacedHelpers } from 'vuex'
import { mapState as mapStateRegular, mapActions as mapActionsRegular } from 'vuex'
import Modal from 'bootstrap/js/dist/modal'
import RowManageUsers from '@/components/RowManageUsers.vue'

const { mapState, mapActions } = createNamespacedHelpers('users')

export default {
    components: {
      RowManageUsers,
      Pagination,
    },
    MAX_USERS: 10,
    data() {
        return {
            userToDelete: null,
            invitation: {
                email: null,
                role: null
            },
            inviteSent: false,
            errorInvite: false
        }
    },
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page']),
      ...mapStateRegular({ groups: state => state.groups.items }),
      groupsWithoutOwner() {
        return this.groups.filter(group => group.name !== 'Owner')
      }
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList', 'deleteUser', 'inviteMember']),
      ...mapActionsRegular(
          ['groups/init']
      ),     
    
      showConfirmUserDelete(user) {
          this.userToDelete = user;
          (new Modal( document.getElementById('confirmUserDelete'))).show()
      },
      showInviteModal() {
          (new Modal( document.getElementById('userInviteModal'))).show()
      },
      handleInviteSent() {
          this.inviteSent = true
          setTimeout(() => {
              this.inviteSent = false
          }, 5000)
      },
      handleInviteError() {
          this.errorInvite = true
          setTimeout(() => {
              this.errorInvite = false
          }, 5000)
      }
    },
    mounted() {
      this.init()
      this['groups/init']()
    }
}
</script>
