<template>
  <div>
    <div class="container-full mx-2">
      <div class="row vh-100">
        <div class="col-md-2 overflow-auto sidebar">
          <nav class="navbar navbar-expand-md navbar-light">
            <router-link to="/" title="Website Title">
              <img
                src="../assets/logo_app.jpg"
                class="navbar-brand"
                border="0"
                alt="Website"
                style="max-width: 170px"
              />
            </router-link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#topnavbar"
              aria-controls="topnavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div id="topnavbar" class="collapse navbar-collapse">
              <ul id="menu-main-menu" class="navbar-nav flex-column" @click="hideMenu()">
                <NavItem :routeName="'ValidationBulk'">
                  <template #icon>
                    <BIconFolderPlus />
                  </template>
                  Bulk Validation
                </NavItem>
                <NavItem :routeName="'ValidationSingle'">
                  <template #icon>
                    <BIconEnvelope />
                  </template>
                  Single Validation
                </NavItem>
                <NavItem :routeName="'ApiKeys'">
                  <template #icon>
                    <BIconKey />
                  </template>
                  <!-- API - Keys & Usage -->
                  API - Keys
                </NavItem>
                <NavItem :routeName="'Statistics'">
                  <template #icon>
                    <BIconBarChart />
                  </template>
                  Statistics
                </NavItem>
                <hr />
                <template v-if="adminOrOwner">
                  <NavItem :routeName="'Plans'">
                    <template #icon>
                      <BIconCreditCard />
                    </template>
                    Plans & Upgrade
                  </NavItem>
                  <NavItem :routeName="'Payments'">
                    <template #icon>
                      <BIconReceiptCutoff />
                    </template>
                    Payments History
                  </NavItem>
                  <hr />
                </template>
                <NavItem :routeName="'UserProfile'">
                  <template #icon>
                    <BIconPersonCircle />
                  </template>
                  My User Profile
                </NavItem>
                <NavItem :routeName="'ManageUsers'" v-if="adminOrOwner">
                  <template #icon>
                    <BIconPeople />
                  </template>
                  Manage Users
                </NavItem>
                <NavItem :href="docServer + 'docs'">
                  <template #icon>
                    <BIconFileText />
                  </template>
                  Docs
                </NavItem>
                <hr />
                <NavItem :routeName="'RemoveAccount'">
                  <template #icon>
                    <BIconTrash class="text-danger" />
                  </template>
                  Remove
                  <span v-if="$store.state.auth.groupName === 'Owner'">Team</span> Account
                </NavItem>
              </ul>
            </div>
          </nav>
        </div>
        <div class="col-md h-100 overflow-auto pt-2">
          <div class="d-flex flex-wrap justify-content-between mb-4">
            <div class="d-flex flex-wrap">
              <Balance class="me-3" />
              <Owner />
            </div>
            <UserWidget class="text-end" />
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// maket https://www.codeply.com/p/ru2u3C9XbM
import { mapGetters, mapActions } from 'vuex'
import {
  BIconEnvelope,
  BIconFolderPlus,
  BIconKey,
  BIconBarChart,
  BIconCreditCard,
  BIconReceiptCutoff,
  BIconFileText,
  BIconPersonCircle,
  BIconPeople,
  BIconTrash
}
  from 'bootstrap-icons-vue'
import NavItem from '@/components/NavItem.vue'
import Balance from '@/components/Balance.vue'
import UserWidget from '@/components/UserWidget.vue'
import Owner from '@/components/Owner.vue'

export default {
  name: 'AppLayout',
  components: {
    NavItem,
    Balance,
    UserWidget,
    Owner,
    BIconEnvelope,
    BIconFolderPlus,
    BIconKey,
    BIconBarChart,
    BIconCreditCard,
    BIconReceiptCutoff,
    BIconFileText,
    BIconPersonCircle,
    BIconPeople,
    BIconTrash
  },
  computed: {
    adminOrOwner() {
      const groupName = this.$store.state.auth.groupName
      return groupName == 'Owner' || groupName == 'Admin'
    },
    docServer() {
      return process.env.VUE_APP_DOC_SERVER
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
  },
  methods: {
    hideMenu() {
      document.querySelector('#topnavbar').classList.remove('show')
      document.querySelector('.navbar-toggler').classList.add('collapsed')
    }
  }
}
</script>

<style>
/* @media (min-width: 768px) {
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
} */
.sidebar {
  background: #f6f8fe;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: column wrap !important;
    flex-flow: column wrap !important;
    align-items: flex-start !important;
  }
  .sidebar {
    height: 100% !important;
  }
}
</style>
