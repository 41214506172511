<template>
    <div>
        <h5>
            Lists Statistics
        </h5>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>NAME</th>
                        <th>STATUS</th>
                        <th>ROWS</th>
                        <!-- <th>NON_EMAILS</th> -->
                        <th>INVALID</th>
                        <th>SAFE</th>
                        <th>RISKY</th>
                        <th>UNKNOWN</th>
                        <th>USER</th>
                        <th>DEL</th>
                    </tr>
                </thead>
                <tbody :class="{'opacity-25' : isLoading}">
                <tr v-for="(item, index) in items" :key="index">
                    <td>{{ (new Date(item.created)).toLocaleString() }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.status_text }}</td>
                    <td>{{ item.num_records }}</td>
                    <!-- <td>{{ item.num_non_emails }}</td> -->
                    <td>{{ item.num_invalid }}</td>
                    <td>{{ item.num_safe }}</td>
                    <td>{{ item.num_risky }}</td>
                    <td>{{ item.num_unknown }}</td>
                    <td>{{ item.user.email }}</td>
                    <td>{{ item.deleted ? 'Yes' : 'No' }}</td>
                </tr>
                </tbody>
            </table>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
                </div>
            <Pagination :totalRows="totalRows" :page="page" @update:page="changePage($event)" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('bulkVal')

export default {
    name: 'ListStatistics',
    components: {
      Pagination
    },
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page'])
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList'])     
    },
    mounted() {
      this.init(true)
    }
}
</script>