<template>
    <div>
        <div class="d-flex justify-content-between text-primary">
            <div>
                {{ status }} &ndash; {{ num }}
            </div>
            <div>{{ percent }}%</div>
        </div>
        <div class="progress mb-4" style="height: 10px;">
            <div class="progress-bar b1g-secondary" :style="{width: percent+'%'}">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ValidationBulkListStatElem',
    props: {
        status: {
            type: String,
            required: true
        },
        num: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },
    computed: {
        percent() {
            return Math.round(this.num / this.total * 100)
        }
    }
}
</script>
