<template>
  <component :is="layout" />
</template>

<script>
/* eslint-disable */
import AppLayout from '@/layouts/App.vue'
import PublicLayout from '@/layouts/Public.vue'

export default {
  components: {
    AppLayout,
    PublicLayout
  },  
  computed: {
    layout() {      
        // console.log(this.$route, 'this.$route.meta.layout')
        if (this.$route.name && !this.$route?.meta.layout) {
          return 'AppLayout'
        }
        return this.$route.meta.layout
    }
  }
}
</script>
