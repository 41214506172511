/* eslint-disable */
import axios from 'axios'

const getDefaultState = () => {
  return {
    items: [],
    totalRows: 0,
    error: null,
    isLoading: false,
    page: 1
  }
}
const state = getDefaultState()

export default {
    namespaced: true,    
    state,    
    mutations: {
      SET_PAGE (state, {page}) {
        state.page = page
      },
      INIT (state) {
        Object.assign(state, getDefaultState())        
      },
      REQUEST_LIST (state) {        
        state.isLoading = true
      },
      RECEIVE_LIST_ERROR (state, {error}) {
        state.isLoading = false
        state.error = error
      },
      RECEIVE_LIST_SUCCESS (state, {users, invites}) { 
        state.items = []       
        invites.forEach( invite => {
          invite.type = 'invite'
          state.items.push(invite)
        })
        users.forEach( user => {
          user.type = 'user'
          state.items.push(user)
        })
        state.isLoading = false
        // state.items = items
        state.totalRows = 1
      },
      CHANGE_GROUP_SUCCESS (state, {user, groupId}) {
        user.groups = [groupId]
      },
      CHANGE_GROUP_INVITE_SUCCESS(state, {invite, groupId}) {
        invite.role = groupId
      }
    },
    actions: {
      loadList: ({commit, state}) => {
        // axios.get(`users/?&page=${state.page}`)
        axios.get(`team/?&page=${state.page}`)
        .then(({ data, status }) => {
          if (status === 200) {
            commit('RECEIVE_LIST_SUCCESS', {users: data.users, invites: data.invites})
          }
        })
        // .catch(error => {
        //   commit('RECEIVE_LIST_ERROR', {error: error.response.data})
        // });
      },
      changeUserGroup({commit}, {user, groupId}){        
        const userUpdate = {
          id: user.id,
          groups: [groupId]
        }
        axios.patch(`users/${user.id}/`, userUpdate)
          .then(({ data, status }) => {
            if (status === 200) {
              commit('CHANGE_GROUP_SUCCESS', {user, groupId})
            }
          })
          .catch(error => {
            commit('RECEIVE_LIST_ERROR', {error: error.response.data})
          });
      },
      changeInviteGroup({commit}, {invite, groupId}) {
        const inviteUpdate = {
          id: invite.id,
          role: groupId
        }
        axios.patch(`user_invitations/${invite.id}/`, inviteUpdate)
          .then(({ data, status }) => {
            if (status === 200) {
              commit('CHANGE_GROUP_INVITE_SUCCESS', {invite, groupId})
            }
          })
          // .catch(error => {
          //   commit('RECEIVE_LIST_ERROR', {error: error.response.data})
          // });
      },
      deleteUser({commit, dispatch, state}, {user}) {
        if (user.type == 'user') {
          axios.delete(`users/${user.id}`)
          .then( ({data, status}) => {
            state.items = state.items.filter(item => item.id !== user.id || user.type !== 'user')            
          })
        } else {
          axios.delete(`user_invitations/${user.id}`)
          .then( ({data, status}) => {
            state.items = state.items.filter(item => item.id !== user.id || user.type !== 'invite')            
          })
          .catch(error => {
            if (error.response.status === 404) {
              commit('RECEIVE_LIST_ERROR', {error: 'The user has already accepted the invite'})
              dispatch('loadList')
            }
          })
        }
      },
      inviteMember({commit,dispatch}, payload) {        
        axios.post('user_invitations/', payload)
        .then( ({data, status}) => {          
          // console.log('invite sent!')
          dispatch('init')
        })
        .catch(error => {
          // console.log(error.response.data)
          commit('RECEIVE_LIST_ERROR', {error: error.response.data})
        });
      },
      sendInvite({commit,dispatch}, payload) {                
        return axios.post('send_invite/', payload)
        .then( ({data, status}) => {          
          // console.log('invite sent!')          
        })        
      },
      changePage: ({commit, dispatch}, page) => {
        commit('SET_PAGE', {page})
        dispatch('loadList')
      },
      init: ({commit, dispatch}) => {
        commit('INIT')
        dispatch('loadList')
      }
    }
  }  