/* eslint-disable */
import { createStore } from 'vuex'
import auth from './auth'
import singleVal from './singleVal'
import bulkVal from './bulkVal'
import users from './users'
import groups from './groups'
import payments from './payments'
import balance from './balance'
import stat from './stat'
import balance_current from './balance_current'
import keys from './keys'


export default createStore({  
  modules: {
    auth,
    singleVal,
    bulkVal,
    users,
    groups,
    payments,
    balance,
    stat,
    balance_current,
    keys
  }
})
