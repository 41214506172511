<template>
    <div>
        <h5>
            Common Statistics
        </h5>
        <div class="d-flex">
            <div class="card me-3" style="width: 18rem;">
                <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Total Calls</h6>
                    <h4 class="card-title">{{ items.total?.toLocaleString() }}</h4>
                </div>
            </div>
            <div class="card mx-3" style="width: 18rem;">
                <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Bulk Validation</h6>
                    <h4 class="card-title">{{ items.bulk?.toLocaleString() }}</h4>
                </div>
            </div>
            <div class="card mx-3" style="width: 18rem;">
                <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Single Validation</h6>
                    <h4 class="card-title">{{ items.single?.toLocaleString() }}</h4>
                </div>
            </div>
        </div>
        <!-- <div v-if="error" class="alert alert-danger">
            {{ error }}
        </div> -->
    </div>
</template>

<script>
/* eslint-disable */
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('stat')

export default {
    name: 'CommonStat',
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page'])
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList'])     
    },
    mounted() {
      this.init()
    }
}
</script>