<template>
    <div  class="mx-auto mt-5" style="max-width: 300px">
        <div class="text-center">
            <h5 class="fw-bold">Permission Denied</h5>
            <BIconXOctagon width="100" height="100" class="text-danger my-4"/>
        </div>
        <div>
            <div>
                You don't have enough permission to access the requested page.
            </div>
            <div>
                Please, ask the account owner for assistance.
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { BIconXOctagon } from 'bootstrap-icons-vue'

export default {
    name: 'RestrictedArea',
    components: {
        BIconXOctagon
    },
}
</script>
