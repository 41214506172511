<template>
    <div>
        <h5>
            Payments
        </h5>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>AMOUNT</th>
                        <th>BUYED CREDITS</th>
                        <th>PAYMENT METHOD</th>
                        <th>INVOICE</th>
                    </tr>
                </thead>
                <tbody :class="{'opacity-25' : isLoading}">
                <tr v-for="(item, index) in items" :key="index">
                    <td>{{ (new Date(item.created)).toLocaleString() }}</td>
                    <td>{{ item.payment_sum }} {{ item.payment_currency }}</td>
                    <td>{{ item.credits_amount }}</td>
                    <td>{{ item.payment_method }}</td>
                    <td>
                        <a :href="item.receipt_url" target="_blank">
                            Open
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
                </div>
            <Pagination :totalRows="totalRows" :page="page" @update:page="changePage($event)" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('payments')

export default {
    name: 'PaymentsHistory',
    components: {
      Pagination
    },
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page'])
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList'])
    },
    mounted() {
      this.init()
    }
}
</script>