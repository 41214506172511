<template>
    <div>
        <form class="mx-auto mt-5 pt-4" style="width: 300px" @submit.prevent="login">
            <div class="my-3">
                <img src="../assets/logo.jpg" style="width: 80%" class="ms-2">
            </div>
            <h4 class="mb-3">Login</h4>
            <div class="mb-2">
                Don’t have an account? <router-link :to="{name: 'register'}">Sign up</router-link>
            </div>
            <div v-if="error" class="alert alert-danger" data-cy="error">
                {{ error }}
            </div>
            <div v-if="error == 'User is not acivated'">
                <div>
                    Please, verify your email by clicking the verification link on the email we sent you after registration.
                </div>
                <div>
                    If you don't have this email, click the button below to send a new email.
                </div>
                <ResendEmail :email="email" />
            </div>
            <div class="mb-3">
                <label for="emailInput" class="form-label">Email</label>
                <input
                    @input="error=''"
                    v-model.trim="email"
                    id="emailInput"
                    type="email"
                    required
                    data-cy="email"
                    class="form-control"
                    autocomplete="username"
                >
            </div>
            <div class="mb-3">
                <label for="passwordInput" class="form-label">Password</label>
                <input
                    v-model.trim="password"
                    id="passwordInput"
                    :type="passwordType"
                    required
                    data-cy="password"
                    class="form-control"
                    autocomplete="current-password"
                >
            </div>
            <div class="mb-3 form-check">
                <input v-model="showPassword" type="checkbox" class="form-check-input" id="showPassCheck">
                <label class="form-check-label" for="showPassCheck">Show my password</label>
            </div>
            <button type="submit" :disabled="loading" class="btn btn-primary">Log In</button>
            <div class="mt-2">
                <router-link :to="{name: 'ForgotPassword'}">Forgot your password?</router-link>
            </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
import ResendEmail from '@/components/ResendEmail.vue'

export default {
    components: {
        ResendEmail
    },
    data() {
        return {
            showPassword: false,
            email: '',
            password: '',
            error: '',
            loading: false
        }
    },
    computed: {
        passwordType() {
            return this.showPassword ? 'text' : 'password'
        }
    },
    methods: {
        login() {
            this.loading = true;
            this.error = null;

            this.$store.dispatch("auth/LOGIN", {
                login: this.email,
                password: this.password
            })
            .then(success => {
                this.loading = false
                // this.$router.push("/")
                this.$router.push({ path: this.$route.query.returnUrl || '/'});
            })
            .catch(error => {
                this.loading = false
                this.error = error                
            })            
        }
    }  
}
</script>

<style>

</style>
