<template>
    <div>
        <PaymentsHistory />
        <BalanceHistory class="mt-5"/>
    </div>
</template>

<script>
/* eslint-disable */
import PaymentsHistory from '@/components/PaymentsHistory.vue'
import BalanceHistory from '@/components/BalanceHistory.vue'

export default {
    components: {
      PaymentsHistory,
      BalanceHistory
    },
}
</script>
