<template>
    <div class="my-4">
        <!-- <button class="btn btn-success btn-sm float-end">Upload List</button> -->
        <ul class="nav nav-tabs">
            <!-- <transition-group name="list"> -->
                <li class="nav-item" v-for="status in statuses" :key="status.status">
                <a class="nav-link" :class="{active: status.active}" href="#" @click="selectStatus(status)">{{ status.label }}</a>
            </li>
            <!-- </transition-group> -->
            <li>
                <ListUploader class="1float-end ms-3" @upload="selectStatus(statuses.find(st => st.label === 'All'))" />
            </li>
        </ul>
        <div class="my-3">
            <h5>Lists</h5>
            <ValidationBulkList v-for="list in items" :key="list.id" :list="list" @listDelete="showConfirmListDelete(list)" @listDownload="showDownloadList(list)" />
        </div>
        <Pagination :totalRows="totalRows" :page="page" @update:page="changePage($event)" />
        <div class="modal fade" id="confirmListDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Are you sure to remove this list?</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        This action is irreversible.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteList({list: this.listToDelete})">Yes, remove</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="downloadList" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">Download Report for <span class="fw-bold">{{ listToDownload?.name }}</span></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="form-check form-check-inline1 my-2">
                                <label class="form-check-label" @click="changePreset(['Deliverable', 'Free'])">
                                    <span class="fw-bold">Safe only </span> (Recommended)
                                    <input v-model="exportVariant" :value="1" class="form-check-input" type="radio">
                                </label>
                            </div>
                            <div class="form-check form-check-inline1 my-2">
                                <label class="form-check-label" @click="changePresetInvert(['Invalid', 'Disposable', 'Duplicates', 'Not Emails'])">
                                    <span class="fw-bold">Marketer's choice </span>(all except Invalid and Disposable)
                                    <input v-model="exportVariant" :value="2" class="form-check-input" type="radio">
                                </label>
                            </div>
                            <div class="form-check form-check-inline1 my-2">
                                <label class="form-check-label" @click="changePresetInvert(['Duplicates', 'Not Emails'])">
                                    <span class="fw-bold">Full report </span>(without duplicates)
                                    <input v-model="exportVariant" :value="3" class="form-check-input" type="radio">
                                </label>
                            </div>
                            <div class="form-check form-check-inline1 my-2">
                                <label class="form-check-label" @click="changePresetInvert([])">
                                    <span class="fw-bold">Keep all rows</span> (with duplicates and empty rows)
                                    <input v-model="exportVariant" :value="4" class="form-check-input" type="radio">
                              </label>
                            </div>
                            <div class="form-check form-check-inline1 my-2">
                                <label class="form-check-label">
                                    <span class="fw-bold">Custom Report</span>
                                    <input  v-model="exportVariant" :value="5" class="form-check-input" type="radio">
                                </label>
                            </div>
                        </div>
                        <div class="mt-3" v-if="exportVariant === 5 || true">
                            <div>Export Options</div>
                            <div v-for="filter in exportFilters" :key="filter" class="form-check form-check-inline">
                                <label class="form-check-label">
                                    {{ filter }}
                                    <input  v-model="exportFilterValues[filter]"  :disabled="exportVariant !== 5" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <!-- <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Deliverable
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Invalid
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Catch-All
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Unknown
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Disposable
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Role
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Free
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <label class="form-check-label">
                                    Not Emails
                                    <input  v-model="keepDuplicates2" :value="3" class="form-check-input" type="checkbox">
                                </label>
                            </div> -->
                        </div>
                    </div>
                    <div class="modal-footer1 d-grid gap-2 m-3">
                        <a :href="`${exportUrl}`"
                            class="btn btn-primary rounded-pill"
                            @click="$emit('listDownload', list); this.downloadModal.hide()"
                        >
                            Download report
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import ValidationBulkList from '@/components/ValidationBulkList.vue'
import Modal from 'bootstrap/js/dist/modal'
import ListUploader from '@/components/ListUploader.vue'
import Pagination from '@/components/Pagination.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('bulkVal')

export default {
    components: {
        ValidationBulkList,
        ListUploader,
        Pagination
    },
    data() {
        return {
            statuses: [
                {label: 'All', status: null, active: true},
                {label: 'Ready To Verify', status: 1, active: false},
                {label: 'Verifying', status: 2, active: false},
                {label: 'Completed', status: 3, active: false},
                // {label: 'Preparing', status: 4, active: false},
            ],
            lists: [
                {name: 'testing list1', upload_at: '08/28/2021', status: 'prepare', records: 70, stats: {}},
                {name: 'testing list4', upload_at: '08/28/2021', status: 'completed', records: 50, stats: {}},
                {name: 'testing list2', upload_at: '07/28/2021', status: 'ready', records: 170, stats: {}},
                {name: 'testing list3', upload_at: '06/28/2021', status: 'verifiing', records: 220, stats: {}},                
            ],
            showModal: false,
            listToDelete: null,

            listToDownload: null,
            downloadModal: null,
            exportVariant: 2,
            exportFilters: ['Deliverable', 'Invalid', 'Catch-All', 'Unknown', 'Disposable', 'Role', 'Free', 'Duplicates', 'Not Emails'],
            exportFilterValues: {}
        }
    },
    computed: {
        ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page']),
        exportUrl() {
            const id = this.listToDownload?.id
            if (!id) {
                return ''
            }
            return `${process.env.VUE_APP_API_SERVER}api/v1/validation_lists/${id}/download?export_filters=${JSON.stringify(this.exportFilterValues)}`
        }
    },
    methods: {
        ...mapActions(['init', 'changePage', 'loadList', 'changeQuery', 'deleteList']),
        test() {
            console.log('modal closed')
        },
        selectStatus(status) {
            this.statuses.forEach(status => {
                status.active = false
            });
            status.active = true
            this.changeQuery({status: status.status})
        },
        showConfirmListDelete(list) {
            this.listToDelete = list;
            (new Modal( document.getElementById('confirmListDelete'))).show()
        },
        showDownloadList(list) {
            this.listToDownload = list;
            this.downloadModal = (new Modal( document.getElementById('downloadList')))
            this.downloadModal.show()
        },
        changePreset(trueList) {
            this.exportFilters.forEach(filter => {
                this.exportFilterValues[filter] = trueList.includes(filter)
            })
        },
        changePresetInvert(trueList) {
            this.exportFilters.forEach(filter => {
                this.exportFilterValues[filter] = !trueList.includes(filter)
            })
        }
    },
     mounted() {
      this.init()
      this.changePresetInvert(['Invalid', 'Disposable', 'Duplicates', 'Not Emails'])
    }
}
</script>

<style>

.snake.active {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;
}

@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

</style>
