<template>
  <div>
    <h5>
        Single Validation History
    </h5>
    <button @click="loadList" class="btn btn-primary btn-sm float-end">Refresh Table</button>
    <div class="btn-group">
      <a :href="`${exportUrl}?format=csv`"
        class="btn btn-secondary btn-sm"
      >
        CSV
      </a>
      <a :href="`${exportUrl}?format=xlsx`"
        class="btn btn-success btn-sm"
      >
        Excel
      </a>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Email</th>
            <th>Result</th>
            <th>Deliverable</th>
            <th>Disposable</th>
            <th>Role</th>
            <th>Free</th>
            <th>Date</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody :class="{'opacity-25' : isLoading}">
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.email }}</td>
            <td>{{ item.result }}</td>
            <td>
              {{ item.status_text }}
              <template v-if="item.substatus_text">
                ({{ item.substatus_text }})
              </template>
            </td>
            <td>{{ item.is_disposable }}</td>
            <td>{{ item.is_role }}</td>
            <td>{{ item.is_free }}</td>
            <td>{{ (new Date(item.created)).toLocaleString() }}</td>
            <td>{{ item.user.email }}</td>
          </tr>
        </tbody>
      </table>
       <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>
      <Pagination :totalRows="totalRows" :page="page" @update:page="changePage($event)" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('singleVal')

export default {
    name: 'SingleValidationHistory',
    components: {
      Pagination
    },
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page']),
      exportUrl() {
        return `${process.env.VUE_APP_API_SERVER}api/v1/validation_history/export`
      }
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList'])
    },
    mounted() {
      this.init()
    }
}
</script>
