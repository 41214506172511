<script>
/* eslint-disable */
export default {
    data() {
        return {           
        }
    },     
    mounted() {
        console.log(this.$route.query)
        this.$store.dispatch("auth/VERIFY_CHANGE_EMAIL", {
            user_id: this.$route.query.user_id,
            email: this.$route.query.email,
            timestamp: this.$route.query.timestamp,
            signature: this.$route.query.signature
        })
        .then(success => {            
            this.$router.push({name: 'login'})
        })
        .catch(error => {                        
        })   
    }

}
</script>

<style>

</style>
