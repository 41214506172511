<template>
    <tr>
        <td>{{ user.email }}</td>
        <td>
            <div>
                {{ userGroup?.name }}
            </div>
            <div style="font-size: 80%" v-if="isNotOwner">
                Set to:
                <div v-for="group in groupsToChoose" :key="group.id" class="d-inline mx-1" @click="changeGroup($event, user, group)">
                    <a href="">{{ group.name + ' ' }}</a>
                </div>
            </div>
        </td>
        <td></td>
        <td>
            <template v-if="user.type === 'user'">
                {{ (new Date(user.date_joined)).toLocaleString() }}
            </template>
        </td>
        <td>
            <div v-if="isNotOwner">
                <div title="Resend invitation" class="d-inline" :class="{'disabled-button': sendingInvite}" @click="doSendInvite(user)">
                    <BIconEnvelope class="text-primary" :style="{visibility: user.type === 'user' ? 'hidden' : ''}"  />
                </div>
                <div title="Remove user" class="d-inline mx-3" @click="$emit('userDelete', user)">
                    <BIconXSquareFill class="text-danger" />
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import { BIconEnvelope, BIconXSquareFill } from 'bootstrap-icons-vue'
import { createNamespacedHelpers, mapState as mapStateRegular, mapActions as mapActionsRegular } from 'vuex'

const { mapActions } = createNamespacedHelpers('users')

export default {
    name: 'RowManageUsers',
    components: {
        BIconEnvelope,
        BIconXSquareFill
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            sendingInvite: false
        }
    },
    computed: {
        ...mapStateRegular({ groups: state => state.groups.items }),
        userGroup() {
            const user = this.user
            let groupId
            if (user.type === 'user') {
                groupId = user.groups[0]
            } else {
                groupId = user.role
            }
            return this.groups.find(group => group.id === groupId)
        },
        groupsToChoose() {
            return this.groups.filter(group => group.name !== 'Owner' && group.id !== this.userGroup.id)
        },
        isNotOwner() {
            return this.userGroup?.name !== 'Owner'
        }
    },
    methods: {
        ...mapActions(['changeUserGroup', 'changeInviteGroup', 'sendInvite']),
        ...mapActionsRegular(
            ['groups/init']
        ),
        changeGroup(event, user, group) {
            event.preventDefault()
            if (user.type === 'user') {
                this.changeUserGroup({ user, groupId: group.id })
            } else {
                this.changeInviteGroup({ invite: user, groupId: group.id })
            }
        },
        doSendInvite(user) {
            this.sendingInvite = true
            this.$emit('inviteSending')
            this.sendInvite(user)
                .then(() => {
                    this.sendingInvite = false
                    this.$emit('inviteSent')
                })
                .catch(() => {
                    this.sendingInvite = false
                    this.$emit('inviteError')
                })
        }
    }
}
</script>

<style scoped>
    .disabled-button{
        opacity: 0.5;
        pointer-events: none;
    }
</style>
