<template>
    <form class="mx-auto mt-5" style="width: 300px" @submit.prevent="changeEmail">
        <h4 class="mb-3">Change Your Email</h4>
        <div class="my-3">
         You will receive a confirmation email at your new email address you need to click on.
         In order for the change to take effect.
        </div>
        <div v-if="error" class="alert alert-danger" data-cy="error">
            {{ error }}
        </div>
        <div v-if="success" class="alert alert-success">
            Check your inbox for the email with a confirmation link
        </div>
        <div class="mb-3">
          <label for="emailInput" class="form-label">New Email</label>
          <input
              v-model.trim="email1"
              id="emailInput"
              type="email"
              required
              class="form-control"
          >
        </div>
        <div class="mb-3">
          <label for="emailInput2" class="form-label">Confirm New Email</label>
          <input
              v-model.trim="email2"
              id="emailInput2"
              type="email"
              required
              class="form-control"
          >
        </div>
        <button type="submit" :disabled="loading" class="btn btn-primary">Change Email</button>
    </form>
</template>

<script>
/* eslint-disable */

export default {
    name: 'ChangeEmail',
    data() {
        return {
            email1: '',
            email2: '',
            error: '',
            success:'',
            loading: false
        }
    },
    computed: {
      // ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page'])
    },
    methods: {
      // ...mapActions(['init', 'changePage', 'loadList']),
      changeEmail() {
        this.error = this.success = ''
        if (this.email1 !== this.email2) {
            this.error = "Emails don't match"
            return
        }
        this.$store.dispatch("auth/changeEmail", {
            email: this.email1,
        })
        .then(success => {
            this.success = true
            // this.$store.commit('auth/LOGOUT_SUCCESS')
            // this.$router.push({name: 'login'})
        })
        .catch(error => {                
            if (error.response.data 
                && error.response.data.email
                && error.response.data.email.toString().includes('address already exists')
            ) {
                this.error = 'User with this email address already exists'
            }
              else {
                this.error = error.response.data;
            }                
        })
      }
    },
    mounted() {
      // this.init()
    }
}
</script>