<template>
    <div>
      <CommonStat class="mb-5"/>
      <ListStatistics />
    </div>
</template>

<script>
/* eslint-disable */
import ListStatistics from '@/components/ListStatistics.vue'
import CommonStat from '@/components/CommonStat.vue'

export default {
    components: {
      ListStatistics,
      CommonStat
    },
}
</script>
