<template>
    <div class="my-3 p-4 mx-auto" style="max-width: 600px; border: 1px solid grey">
        <div title="Delete list" v-if="list.status_text != 'Verifying'">
            <BIconTrash class="text-danger fw-bold float-end ms-4" width="18px" height="18px" @click="$emit('listDelete', list)"/>
        </div>
        <span class="text-success fw-bold float-end">{{ list.status_text }}</span>
        <div :title="`This list was uploaded by ${list.user.email}`">
            <BIconPersonFill class="text-primary float-start me-2" />
        </div>
        <h5 class="fw-bold">{{ list.name }}</h5>
        {{ (new Date(list.created)).toLocaleString() }}
        <hr>
        <div class="d-flex flex-wrap justify-content-between">
            <div v-if="1 || list.num_emails">
                <div>
                    Number of emails
                </div>
                <div>
                    {{ (list.num_records - list.num_non_emails).toLocaleString() }}
                </div>
            </div>
            <div v-if="list.status_text == 'Completed'">
                Finished at {{ (new Date(list.finished)).toLocaleString() }}
                <div>
                    <div class="btn-group my-2">
                        <button
                            @click="resultsVisible = !resultsVisible"
                            class="btn btn-sm btn-secondary"
                        >
                            {{ resultsVisible ? 'Hide' : 'Show' }} statistics
                        </button>
                        <a
                            class="btn btn-success btn-sm"
                            @click="$emit('listDownload', list)"
                        >
                            Download report
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="list.status_text == 'Ready'">
                <button class="btn btn-sm btn-primary" @click="startVal">
                    Start Verification
                </button>
                <div style="font-size: 80%" class="mt-2">
                    You will pay {{ list.num_records - list.num_non_emails }} Credits
                </div>
            </div>
        </div>

        <div v-if="list.status_text == 'Ready' && startError" class="alert alert-danger text-center mt-4">
            {{ startError }}
        </div>
        <div v-if="list.status_text == 'Verifying' || list.status_text == 'Aborting' || list.status_text == 'Wait Complete'">
            <div class="progress my-4">
                <div class="progress-bar" :style="{width: progress+'%'}" :class="{'bg-danger' : list.status_text == 'Aborting' || list.status_text == 'Wait Complete'}">
                    {{ progress }}%
                </div>
            </div>
            <div class="text-center" v-if="list.status_text == 'Verifying'">
                <button class="btn btn-sm btn-danger" @click="abortVal">
                    Abort Verification
                </button>
                <div class="mt-1" style="font-size: 80%">
                    Abort verification and mark the rest emails as Aborted
                </div>
            </div>
        </div>
        <div v-if="resultsVisible" class="d-flex flex-wrap mt-4">
            <!-- <ValidationBulkListStatElem class="w-50 px-3" status="Safe" :num="list.num_safe" :total="list.num_records"/>
            <ValidationBulkListStatElem class="w-50 px-3" status="Invalid" :num="list.num_invalid" :total="list.num_records"/> -->

            <ValidationBulkListStatElem
                class="w-50 px-3"
                v-for="status in statStatuses"
                :key="status"
                :status="status"
                :num="list['num_'+ status.toLowerCase()]"
                :total="list.num_records"
            />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { BIconTrash, BIconPersonFill } from 'bootstrap-icons-vue'
import axios from 'axios'
import ValidationBulkListStatElem from '@/components/ValidationBulkListStatElem.vue'
const POLLING_INTERVAL = 5000

// TODO перенести данные во vuex
export default {
    name: 'ValidationBulkList',
    components: {
        BIconTrash,
        BIconPersonFill,
        ValidationBulkListStatElem
    },
    props: {
        list: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            resultsVisible: false,
            interval: null,
            progress: 0,
            startError: null,
            statStatuses: ['Safe', 'Invalid', 'Unknown', 'Risky']
        }
    },
    computed: {
        // exportUrl() {
        //     const id = this.list?.id
        //     if (!id) {
        //         return ''
        //     }
        //     return `${process.env.VUE_APP_API_SERVER}api/v1/validation_lists/${id}/download`
        // }
    },
    methods: {
        getList() {
            axios.get(`validation_lists/${this.list.id}/`)
                .then((resp) => {
                    const data = resp.data                    
                    if (this.list.status_text == 'Verifying'  || this.list.status_text == 'Aborting' || this.list.status_text == 'Wait Complete') {
                        const progress = Math.floor(data.num_emails / data.num_records * 100)
                        this.progress = progress
                        // if (progress == 100) {
                        if (data.status_text == 'Completed') {
                            this.list.finished = data.finished                            
                            this.statStatuses.forEach(stat => {
                                const statName = 'num_'+stat.toLowerCase() 
                                this.list[statName] = data[statName]
                            });
                            clearInterval(this.interval)
                            this.$store.dispatch('balance_current/init')
                        }
                    }
                    if (this.list.status_text == 'Preparing') {
                        if (data.status_text == 'Ready') {
                            // this.list.status_text = data.status_text
                            this.list.num_records = data.num_records
                            this.list.num_emails = data.num_emails
                            clearInterval(this.interval)
                        }
                    }
                    this.list.status_text = data.status_text
                })
        },
        startPolling() {
            this.interval = setInterval(() => {
                console.log('interval')
                this.getList()                        
            }, POLLING_INTERVAL)
        },
        startVal() {
            this.$store.dispatch('bulkVal/startVal', { list: this.list })
                .then(() => {
                    this.list.status_text = 'Verifying'
                    this.startPolling()
                    this.$store.dispatch('balance_current/init')
                })
                .catch(error => {
                    if (error.response.status == 402) {
                        this.startError = "You don't have enough credits. Please, buy some."
                    } else {
                        this.startError = String(error)
                    }
                    setTimeout(() => {
                        this.startError = null
                    }, 3000)
                })   
        },
        abortVal() {
            this.$store.dispatch('bulkVal/abortVal', { list: this.list })
                .then(() => {
                    this.list.status_text = 'Aborting'
                    // this.startPolling()
                    // this.$store.dispatch('balance_current/init')
                })
        }
    },
    mounted() {
        if (this.list.status_text == 'Verifying' || this.list.status_text == 'Preparing') {
            this.startPolling()
        }
    },
    unmounted() {
        clearInterval(this.interval)
    }
}
</script>
