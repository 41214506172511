<template>
    <div class="mx-3">
        <SingleValidationCheck />
        <SingleValidationHistory />
    </div>
</template>

<script>
/* eslint-disable */
import SingleValidationHistory from '@/components/SingleValidationHistory.vue'
import SingleValidationCheck from '@/components/SingleValidationCheck.vue'

export default {
    components: {
        SingleValidationHistory,
        SingleValidationCheck        
    }
}
</script>
