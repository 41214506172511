<template>
  <div>
    <h4>Plans</h4>
    <div class="alert alert-primary">
      Your credits will never expire. No monthly payments.
    </div>
    <PlanCalculator :prices="prices" v-model:emailsCount="emailsCount" @buy="checkout" />
    <div class="table-responsive my-4">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Number of emails</th>
            <th>Price per email</th>
            <th>Price</th>
            <th>Buy</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in prices" :key="index">
            <td>{{ item.amount.toLocaleString() }}</td>
            <td>${{ (item.cost/item.amount).toLocaleString([], {maximumFractionDigits: 10}) }}</td>
            <td>${{ item.cost }}</td>
            <td><button class="btn btn-primary btn-sm" @click="buyPackage(item)">Buy</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center">
      <img src="@/assets/payments.png" style="max-width: 300px">
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PlanCalculator from '@/components/PlanCalculator.vue'
import axios from "axios"

const BALANCE_LOAD_TIMEOUT = 3000

export default { 
    components: {
      PlanCalculator
    },
    data() {
      return {
        emailsCount: 5000,        
        prices: [
          { amount: 1000, cost: 5 },
          { amount: 5000, cost: 10 },
          { amount: 10000, cost: 15 },
          { amount: 25000, cost: 30 },
          { amount: 50000, cost: 50 },
          { amount: 100000, cost: 90 },
          { amount: 200000, cost: 140 },
          { amount: 500000, cost: 300 },
          { amount: 1000000, cost: 500 },
          { amount: 2000000, cost: 800 },
          { amount: 5000000, cost: 1500 },
        ]
      }
    },
    computed: {      
    },
    mounted() {                 
        const paddleScript = document.createElement('script')
        paddleScript.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js')
        paddleScript.onload = () => {
            Paddle.Environment.set(process.env.VUE_APP_PADDLE_ENVIRONMENT)            
            Paddle.Setup({ 
              vendor: +process.env.VUE_APP_PADDLE_VENDOR_ID,
              eventCallback: (data) => {   
                if (data.event === "Checkout.Complete") {
                  setTimeout(() => {
                    this.$store.dispatch('balance_current/init')
                  }, BALANCE_LOAD_TIMEOUT)
                }
              }
            })
        }
        document.head.appendChild(paddleScript)
    },
    methods: {
      checkout() {        
        const email = this.$store.state.auth.userEmail
        // TODO email absent handling        
        // TODO add spinner
        axios.get('/checkout_link/?emails_count='+this.emailsCount)
          .then(r => {            
            // TODO: err handling
            const link = r.data.link
            return link
          })
          .then(link => {
            Paddle.Checkout.open({ 
              email,
              disableLogout: 1,
              override: link
            })
          })
      },
      buyPackage(item) {
        this.emailsCount = item.amount
        this.checkout()
      },      
    }
}
</script>
