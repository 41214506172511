<template>
    <div>
        <h5>
            Balance Changes
        </h5>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>DATE</th>
                        <th>BALANCE CHANGE</th>
                        <th>DESCRIPTION</th>
                    </tr>
                </thead>
                <tbody :class="{'opacity-25' : isLoading}">
                <tr v-for="(item, index) in items" :key="index">
                    <td>{{ (new Date(item.created)).toLocaleString() }}</td>
                    <td :class="{'text-success' : item.credits_amount > 0, 'text-danger' : item.credits_amount < 0 }">
                        {{ item.credits_amount > 0 ? '+' : '-' }}
                        {{ Math.abs(item.credits_amount).toLocaleString() }}
                    </td>
                    <td>{{ calcDescription(item) }}</td>
                </tr>
                </tbody>
            </table>
            <div v-if="error" class="alert alert-danger">
                {{ error }}
                </div>
            <Pagination :totalRows="totalRows" :page="page" @update:page="changePage($event)" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Pagination from '@/components/Pagination.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('balance')

const REASON_VALIDATION = 'Validation'
const REASON_RETURN_FOR_UNKNOWN = 'Return For Unknown'
const REASON_RETURN_FOR_DUPLICATE = 'Return For Duplicate'
const REASON_RETURN_FOR_ABORT = 'Return For Abort'
const REASON_PURCHASE = 'Purchase'
const REASON_INITIAL_DEPO = 'Initial Depo'

export default {
    name: 'BalanceHistory',
    components: {
      Pagination
    },
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page'])
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList']),
      calcDescription(item) {
          const reason = item.reason_text
          if (reason == REASON_VALIDATION) {
              return `Validation of the list (${item.val_list.name})`
          }
          if (reason == REASON_RETURN_FOR_UNKNOWN) {
              return `Return for Unknown emails: (list ${item.val_list.name})`
          }
          if (reason == REASON_RETURN_FOR_ABORT) {
              return `Return for Aborted validations: (list ${item.val_list.name})`
          }
          if (reason == REASON_RETURN_FOR_DUPLICATE) {
              return `Return for duplicate emails: (list ${item.val_list.name})`
          }
          if (reason == REASON_PURCHASE) {
              return `Purchase of ${item.credits_amount} credits`
          }
          if (reason == REASON_INITIAL_DEPO) {
              return 'Free Initial Deposit'
          }                  
      }
    },
    mounted() {
      this.init()
    }
}
</script>