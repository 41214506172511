<template>
    <form class="mx-auto mt-5" style="width: 300px" @submit.prevent="changePassword">
        <h4 class="mb-3">Change Your Password</h4>
        <div class="my-3">
          You will be redirected to the login screen upon completion
        </div>
        <div v-if="error" class="alert alert-danger" data-cy="error">
            {{ error }}
        </div>
        <!-- TODO fill up with actual email -->
        <input type="text" autocomplete="username" style="display: none">
        <div class="mb-3">
            <label for="passwordInput1" class="form-label">Old Password</label>
            <input
              v-model.trim="passwordOld"
              id="passwordInput1"
              :type="passwordType"
              required
              data-cy="password"
              class="form-control"
              autocomplete="current-password"
            >
        </div>
        <div class="mb-3">
            <label for="passwordInput2" class="form-label">New Password</label>
            <input
              v-model.trim="passwordNew1"
              id="passwordInput2"
              :type="passwordType"
              required
              data-cy="password"
              class="form-control"
              autocomplete="new-password"
            >
        </div>
        <div class="mb-3">
            <label for="passwordInput3" class="form-label">Confirm New Password</label>
            <input
              v-model.trim="passwordNew2"
              id="passwordInput3"
              :type="passwordType"
              required
              data-cy="password"
              class="form-control"
              autocomplete="new-password"
            >
        </div>
        <div class="mb-3 form-check">
            <input v-model="showPassword" type="checkbox" class="form-check-input" id="showPassCheck">
            <label class="form-check-label" for="showPassCheck">Show passwords</label>
        </div>
        <button type="submit" :disabled="loading" class="btn btn-primary">Change Password</button>
    </form>
</template>

<script>
/* eslint-disable */

export default {
    name: 'ChangePassword',
    data() {
        return {
            showPassword: false,
            passwordOld: '',
            passwordNew1: '',
            passwordNew2: '',
            error: '',
            loading: false
        }
    },
    computed: {
      passwordType() {
          return this.showPassword ? 'text' : 'password'
      },
      // ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page'])
    },
    methods: {
      // ...mapActions(['init', 'changePage', 'loadList']),
      changePassword() {
        this.error = ''
        if (this.passwordNew1 !== this.passwordNew2) {
            this.error = "Passwords don't match"
            return
        }
        this.$store.dispatch("auth/changePassword", {
            old_password: this.passwordOld,
            password: this.passwordNew1,
            password_confirm: this.passwordNew2,
        })
        .then(success => {
            this.$store.commit('auth/LOGOUT_SUCCESS')
            this.$router.push({name: 'login'})
        })
        .catch(error => {                
            if (error.response.data 
                && error.response.data.email
                && error.response.data.email.toString().includes('address already exists')
            ) {
                this.error = 'User with this email address already exists'
            }
              else {
                this.error = error.response.data;
            }                
        })
      }
    },
    mounted() {
      // this.init()
    }
}
</script>