<template>
    <div v-if="ownerEmail && ownerEmail !== userEmail">
        ( Owner:
            {{ ownerEmail }}
        )
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'

export default {
    name: 'Owner',
    computed: {
        ...mapState({
            ownerEmail: state => state.auth.ownerEmail,
            userEmail: state => state.auth.userEmail,
        }),
    }
}
</script>
