<template>
    <div>
        <div class="mx-auto mt-5 pt-4"  style="width: 300px">
            <div class="my-3">
                <img src="../assets/logo.jpg" style="width: 80%" class="ms-2">
            </div>
        </div>
        <form v-if="!success" class="mx-auto" style="width: 300px" @submit.prevent="submit">
            <h4 class="mb-3">Reset Password</h4>
            <div v-if="error" class="alert alert-danger">
                <div>
                    There is some problem with password reset. Maybe the link is outdated.
                </div>
                <div>
                    Please, try again or request the new email <router-link :to="{name: 'ForgotPassword'}">here</router-link>
                </div>
            </div>
            <div class="mb-3">
                <label for="passwordInput1" class="form-label">Password</label>
                <input
                    v-model.trim="password1"
                    id="passwordInput1"
                    :type="passwordType"
                    required
                    data-cy="password1"
                    class="form-control"
                    autocomplete="new-password"
                >
            </div>
            <div class="mb-3">
                <label for="passwordInput2" class="form-label">Confirm Password</label>
                <input
                    v-model.trim="password2"
                    id="passwordInput2"
                    :type="passwordType"
                    required
                    data-cy="password2"
                    class="form-control"
                    autocomplete="new-password"
                >
            </div>
            <div class="mb-3 form-check">
                <input v-model="showPassword" type="checkbox" class="form-check-input" id="showPassCheck">
                <label class="form-check-label" for="showPassCheck">Show passwords</label>
            </div>
            <button type="submit" :disabled="loading" class="btn btn-primary">Reset</button>
        </form>
        <div v-if="success" class="mx-auto" style="width: 300px">
            <span class="fw-bold">Success!</span>
            <div>
                Password has been changed. Please, <router-link :to="{name: 'login'}" class="fw-bold">Log in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    data() {
        return { 
            showPassword: false,
            password1: '',
            password2: '',
            error: '',
            loading: false,
            success: false
        }
    },
    computed: {
        passwordType() {
            return this.showPassword ? 'text' : 'password'
        }
    },  
    methods: {
        submit() {
            if (this.password1 !== this.password2) {
                this.error = "Passwords don't match"
                return
            }
            this.loading = true
            this.$store.dispatch("auth/RESET_PASSWORD", {
                user_id: this.$route.query.user_id,
                timestamp: this.$route.query.timestamp,
                signature: this.$route.query.signature,
                password: this.password1
            })
            .then(success => {
                this.success = true
                this.loading = false
            })
            .catch(error => {
                this.loading = false
                this.error = true
            })   
        }
    }

}
</script>