<template>
    <div>
        <div class="mb-3">
            <label for="emailsCount" class="form-label">Enter amount emails to validate:</label>
            <input :value="emailsCount" @input="$emit('update:emailsCount', +$event.target.value)" type="number" id="emailsCount" required class="form-control">
        </div>
        <div v-if="emailsCount > maxAmount" class="text-danger">
            Need more than {{ maxAmount.toLocaleString() }} emails? Contact support, pls.
        </div>
        <div v-else-if="emailsCount < minAmount" class="text-danger">
            Minimum number of emails is {{ minAmount.toLocaleString() }}
        </div>
        <div v-else>
            <button class="btn btn-sm btn-primary me-2" @click="$emit('buy')">
            Buy
            </button>
            <span class="text-secondary fw-bold">{{ emailsCount.toLocaleString() }}</span>
            credits for
            <span class="text-secondary fw-bold">${{ cost.toLocaleString([], {maximumFractionDigits: 2}) }}</span>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'PlanCalculator',
    props: {
        prices: {
            type: Array,
            required: true
        },
        emailsCount: {
            type: Number,
            required: true
        }
    },    
    computed: {      
      cost() {        
        const prices = this.prices        
        if (this.emailsCount > this.maxAmount) {
          return
        }
        if (this.emailsCount < this.minAmount) {
          return
        }
        if (this.emailsCount === this.minElement.amount) {
          return this.minElement.cost
        }
        for (let i = 0; i < prices.length; i++) {
          const priceLevel = prices[i]           
          if (this.emailsCount <= priceLevel.amount) {
            let prev = prices[i-1]
            // linear function
            const linearCoeff = (priceLevel.cost - prev.cost)/(priceLevel.amount - prev.amount) 
            return prev.cost + linearCoeff * (this.emailsCount - prev.amount)
          }
        }        
      },
      minAmount() {
        return this.prices[0].amount
      },
      maxAmount() {
        return this.prices[this.prices.length-1].amount
      },
      minElement() {
        return this.prices[0]
      },
    },
}
</script>
