<template>
  <router-view></router-view>
</template>

<script>
export default {
    name: 'PublicLayout',
    mounted() {
      document.body.style.overflow = 'auto'
    }
}
</script>

<style>
</style>
