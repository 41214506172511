<template>
    <div class="d-flex">
        <div>
            <div>
                {{ $store.state.auth.userEmail }}
            </div>
            <div class="text-secondary">
                {{ groupNameFull }}
            </div>
        </div>
        <a href="#" @click.prevent="logout" data-cy="logout">
            <BIconBoxArrowRight width="30px" height="30px" class="text-secondary ms-3"/>
        </a>
    </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex'
import { BIconBoxArrowRight } from 'bootstrap-icons-vue'

export default {
    name: 'UserWidget',
    components: {
        BIconBoxArrowRight
    },
    computed: {
        ...mapState({
            groupName: state => state.auth.groupName,
            ownerEmail: state => state.auth.ownerEmail,            
        }),
        groupNameFull() {
            if (this.groupName === 'Dev') {
                return 'Developer'
            }
            if (this.groupName == 'Admin') {
                return 'Administrator'
            }
            return this.groupName
        }
    },
    methods: {
        ...mapActions({
            logoutAction: 'auth/LOGOUT'
        }),
        async logout() {
            try {
                await this.logoutAction()
            } finally {
                this.$router.replace({ name: 'login' })
            }
        },
    },
    mounted() {
        // console.log('mounted widget')
        this.$store.dispatch('auth/loadCommonUserData')
            .then(() => {
                const group = this.$store.state.auth.groupName
                const restrictedRoutes = ['Plans', 'Payments', 'ManageUsers']
                if (restrictedRoutes.includes(this.$route.name) && ( group == 'User' || group == 'Dev')) {
                    this.$router.push({ name: 'RestrictedArea' })
                }
            })
    }
}
</script>
