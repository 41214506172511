/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import VerifyEmail from '../views/VerifyEmail.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ValidationSingle from '../views/ValidationSingle.vue'
import ValidationBulk from '../views/ValidationBulk.vue'
import Apikeys from '../views/ApiKeys.vue'
import Statistics from '../views/Statistics.vue'
import Plans from '../views/Plans.vue'
import UserProfile from '../views/UserProfile.vue'
import ManageUsers from '../views/ManageUsers.vue'
import Payments from '../views/Payments.vue'
import VerifyChangeEmail from '../views/VerifyChangeEmail.vue'
import RestrictedArea from '../views/RestrictedArea.vue'
import RemoveAccount from '../views/RemoveAccount.vue'


const publicLayout = 'PublicLayout'

const routes = [
  {
    path: '',
    name: 'ValidationBulk',
    component: ValidationBulk
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: publicLayout
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      layout: publicLayout
    }
  },
  {
    path: '/verify-user/',
    name: 'VerifyEmail',
    component: VerifyEmail,
    meta: {
      layout: publicLayout
    }
  },
  {
    path: '/forgot-password/',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      layout: publicLayout
    }
  },
  {
    path: '/reset-password/',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: publicLayout
    }
  },
  {
    path: '/verify-email/',
    name: 'VerifyChangeEmail',
    component: VerifyChangeEmail,
    meta: {
      layout: publicLayout
    }
  },
  {
    path: '/single-validation/',
    name: 'ValidationSingle',
    component: ValidationSingle
  },
  // {
  //   path: '/bulk-validation/',
  //   name: 'ValidationBulk',
  //   component: ValidationBulk
  // },
  {
    path: '/api/',
    name: 'ApiKeys',
    component: Apikeys
  },
  {
    path: '/statistics/',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/plans/',
    name: 'Plans',
    component: Plans
  },
  {
    path: '/payments/',
    name: 'Payments',
    component: Payments
  },  
  {
    path: '/profile/',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/manage-users/',
    name: 'ManageUsers',
    component: ManageUsers
  },
  {
    path: '/remove-account/',
    name: 'RemoveAccount',
    component: RemoveAccount
  },
  {
    path: '/restricted-area/',
    name: 'RestrictedArea',
    component: RestrictedArea
  },  
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue')
  }  
]

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
