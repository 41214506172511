<template>
    <div>
        <form class="mx-auto mt-5 pt-4" style="width: 300px" @submit.prevent="submit">
            <div class="my-3">
                <img src="../assets/logo.jpg" style="width: 80%" class="ms-2">
            </div>
            <h4 class="mb-3">Forgot Password</h4>
            <div v-if="error" class="alert alert-danger" data-cy="error">
                {{ error }}
            </div>
            <div v-if="linkSent" class="alert alert-success" data-cy="success">
                You will receive a password reset link in email shortly.
                Please, check all folders including spam for the link.
            </div>
            <div class="mb-3">
                <label for="emailInput" class="form-label">Enter your email to proceed</label>
                <input v-model.trim="email" id="emailInput" type="email" required data-cy="email" class="form-control">
            </div>
            <button type="submit" :disabled="loading" class="btn btn-primary">Submit</button>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    data() {
        return {
            email: '',
            error: '',
            linkSent: false,
            loading: false
        }
    },
    methods: {
        submit() {
            this.loading = true
            this.error = null
            this.linkSent = false

            this.$store.dispatch("auth/SEND_RESET_PASSWORD_LINK", {
                login: this.email
            })
            .then(success => {
                this.loading = false
                this.linkSent = true
            })
            .catch(error => {
                this.loading = false
                if (error.response && error.response.status == 400) {
                    this.error = "We can't find this email in any account."
                }
                 else {
                    this.error = error.response.data;
                }
            })            
        }
    }
}
</script>

<style>

</style>
