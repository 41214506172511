/* eslint-disable */
import axios from 'axios'

const getDefaultState = () => {
  return {
    items: [],
    totalRows: 0,
    error: null,
    isLoading: false,
    page: 1
  }
}
const state = getDefaultState()

export default {
    namespaced: true,    
    state,
    mutations: {
      SET_PAGE (state, {page}) {
        state.page = page
      },
      INIT (state) {
        Object.assign(state, getDefaultState())        
      },
      REQUEST_LIST (state) {        
        state.isLoading = true
      },
      RECEIVE_LIST_ERROR (state, {error}) {
        state.isLoading = false
        state.error = error
      },
      RECEIVE_LIST_SUCCESS (state, {items, totalRows}) {
        state.isLoading = false
        state.items = items
        state.totalRows = totalRows
      },
    },
    actions: {
      loadList: ({commit, state}) => {
        commit('REQUEST_LIST')
        // setTimeout(() => {
          axios.get(`groups/?&page=${state.page}`)
          .then(({ data, status }) => {
            if (status === 200) {
              commit('RECEIVE_LIST_SUCCESS', {items: data.results, totalRows: data.count})
            }
          })
          .catch(error => {
            commit('RECEIVE_LIST_ERROR', {error: error.response.data})
          });
        // }, 0)
      },
      changePage: ({commit, dispatch}, page) => {
        commit('SET_PAGE', {page})
        dispatch('loadList')
      },
      init: ({commit, dispatch}) => {
        commit('INIT')
        dispatch('loadList')
      }
    }
  }  