<template>
    <div>
        Credit Balance:
        <router-link
            v-if="group == 'Owner' || group == 'Admin'"
            :to="{name: 'Plans'}"
            class="nav-link d-inline ms-2 p-0 fw-bold"
            title="Buy Credits"
        >
          <span class="badge bg-success" data-cy="balance">
            {{ items?.toLocaleString() }}
          </span>
          +
        </router-link>
        <span v-else class="badge bg-success" data-cy="balance">
          {{ items?.toLocaleString() }}
        </span>
    </div>
</template>

<script>
/* eslint-disable */
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('balance_current')

export default {
    name: 'Balance',
    computed: {
      ...mapState(['items', 'totalRows', 'error', 'isLoading', 'page']),
      group() {
        return this.$store.state.auth.groupName
      }
    },
    methods: {
      ...mapActions(['init', 'changePage', 'loadList']),
    },
    mounted() {
      // this.init()   // we dont need this because we do this router.beforeEach at main.js)
    }
}
</script>