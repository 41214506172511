<template>
  <div class="row my-5 mx-auto" style="max-width: 1200px">
    <form class="col-sm-6" @submit.prevent="check">
        <h4 class="mb-3">Single Validation</h4>
        <div v-if="error" class="alert alert-danger" data-cy="error">
            {{ error }}
        </div>
        <div class="mb-3">
            <label for="emailInput" class="form-label">Enter Email to Check</label>
            <input
              @input="this.$store.state.singleVal.result = ''"
              v-model.trim="email"
              id="emailInput"
              type="email"
              required
              data-cy="email"
              class="form-control"
            >
        </div>
        <button type="submit" :disabled="loading" class="btn btn-primary">Check</button>
    </form>
    <div class="col-sm-6">
        <h4 class="mb-3">The Validation Results</h4>
        <div v-if="loading">
          <div class="spinner-border text-info me-3">
          </div>
          We are checking your email...
        </div>
        <template v-else>
          <div  v-if="!result" class="fw-bold">
            Will be here after the checking
          </div>
          <div v-if="result">
              <div>
                <BIconCheckCircleFill v-if="result.result == 'Safe'" class="text-success me-2" width="40px" height="40px"/>
                <BIconShieldFillX v-if="result.result == 'Invalid'" class="text-danger me-2" width="40px" height="40px" />
                <BIconShieldFillX v-if="result.result == 'Risky'" class="text-warning me-2" width="40px" height="40px" />
                <BIconQuestionCircleFill v-if="result.result == 'Unknown'" class="text-secondary me-2" width="40px" height="40px" />
                The email is {{ result.result }}
              </div>
              <div class="my-4">
                <h5>In Detail:</h5>
                <div>
                  <span class="text-primary">Email: </span>
                  {{ result.email }}
                </div>
                <div>
                  <span class="text-primary">Deliverable: </span>
                  <span :class="{'text-danger': result.status_text === 'Invalid' ||
                        result.status_text === 'Unknown',
                       'text-success': result.status_text === 'Valid'}">
                    {{ statusText }}
                  </span>
                  <span v-if="result.substatus_text">
                      ({{ result.substatus_text.toLowerCase() }})
                  </span>
                </div>
                <div>
                  <span class="text-primary">Disposable: </span>
                  <span :class="{'text-danger': result.is_disposable, 'text-success': !result.is_disposable}">
                    {{ result.is_disposable }}
                  </span>
                </div>
                <div>
                  <span class="text-primary">Role: </span>
                  <span :class="{'text-warning fw-bold': result.is_role, 'text-success': !result.is_role}">
                    {{ result.is_role }}
                  </span>
                </div>
                <div>
                  <span class="text-primary">Free Email: </span>
                  <span>{{ result.is_free }}</span>
                </div>
              </div>
          </div>
        </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {BIconCheckCircleFill, BIconShieldFillX, BIconQuestionCircleFill} from 'bootstrap-icons-vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('singleVal')

export default {
    name: 'SingleValidationCheck',
    components: {
      BIconCheckCircleFill,
      BIconShieldFillX,
      BIconQuestionCircleFill
    },
    data() {
      return {            
        email: '',            
        error: '',
        loading: false            
      }
    },   
    computed: {
      ...mapState(['result']),
      statusText() {
        if (!this.result.status_text) {
          return ''
        }
        if (this.result.status_text == 'Valid') {
          return 'true'
        }
        if (this.result.status_text == 'Invalid') {
          return 'false'
        }
        return this.result.status_text.toLowerCase()
      }     
    },
    methods: {
      ...mapActions(['validate']),
      check() {
        this.loading = true;
        this.error = null;
        this.validate(this.email)        
        .then(() => {
            this.loading = false            
        })            
        .catch(error => {
            this.loading = false
            if (error.response.status == 402) {
              this.error = "You don't have enough credits. Please, buy some."
            } else {
              this.error = String(error)
            }
        })            
      }     
    }    
}
</script>
