<template>
    <li class="nav-item">
        <router-link :to="{name: routeName}" class="nav-link d-flex p-0 mt-2" v-if="routeName">
            <div class="d-flex align-items-center text-primary me-2">
                <slot name="icon"/>
            </div>
            <div>
                <slot />
            </div>
        </router-link>
        <a :href="href" class="nav-link d-flex p-0 mt-2" v-if="href" target="_blank">
            <div class="d-flex align-items-center text-primary me-2">
                <slot name="icon"/>
            </div>
            <div>
                <slot />
            </div>
        </a>
    </li>
</template>

<script>
export default {
    name: 'NavItem',
    props: {
        routeName: {
            type: String,
            default: ''
        },
        href: {
            type: String,
            default: ''
        }
    }
}
</script>
