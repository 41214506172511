<template>
    <div class="mx-auto" style="max-width: 300px; margin-top: 100px" v-if="!loading">
        <div class="my-3">
            <img src="../assets/logo.jpg" style="width: 80%" class="ms-2">
        </div>
        <div v-if="success">
            <h5 class="fw-bold">Email is Verified</h5>
            <div>
                Your email is verified. You can now login into your account.
            </div>
            <div>
                <router-link :to="{name: 'login'}" class="mt-3 d-inline-block fw-bold">
                    Login
                </router-link>
            </div>
        </div>
        <div v-else>
            <div class="fw-bold">
                The verification link is expired or broken.
            </div>
            <div v-if="$route.query.user_id">
                <div>
                    You can send the new link clicking the button below
                </div>
                <ResendEmail :user_id="$route.query.user_id" />
            </div>
            <div v-else>
                Please, try to
                <router-link :to="{name: 'login'}" class="mt-3 d-inline-block fw-bold">
                    Login
                </router-link>
                and send the new email from the login page
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import ResendEmail from '@/components/ResendEmail.vue'

export default {
    components: {
        ResendEmail
    },
    data() {
        return {
            success: false,
            error: null,
            loading: true 
        }
    },     
    mounted() {
        console.log(this.$route.query)
        this.$store.dispatch("auth/VERIFY", {
            user_id: this.$route.query.user_id,
            timestamp: this.$route.query.timestamp,
            signature: this.$route.query.signature
        })
        .then(success => {
            this.success = true
            this.loading = false
        })
        .catch(error => {
            this.error = true
            this.loading = false
        })   
    }

}
</script>

<style>

</style>
