<template>
    <div>
        <div class="mx-auto mt-5" style="width: 300px">
            <div class="my-3">
                <img src="../assets/logo.jpg" style="width: 80%" class="ms-2">
            </div>
        </div>
        <form v-if="!success" class="mx-auto" style="width: 300px" @submit.prevent="register">
            <h4 class="mb-3">Register</h4>
            Already have an account? <router-link :to="{name: 'login'}">Log in</router-link>
            <div v-if="error" class="alert alert-danger" data-cy="error">
                {{ error }}
            </div>
            <div class="mb-3">
                <label for="emailInput" class="form-label">Email</label>
                <input
                    v-model.trim="email"
                    id="emailInput"
                    type="email"
                    required
                    data-cy="email"
                    class="form-control"
                    :disabled="emailDisabled"
                    autocomplete="username"
                >
            </div>
            <div class="mb-3">
                <label for="passwordInput1" class="form-label">Password</label>
                <input v-model.trim="password1" id="passwordInput1" :type="passwordType" required data-cy="password1" autocomplete="new-password" class="form-control">
            </div>
            <div class="mb-3">
                <label for="passwordInput2" class="form-label">Confirm Password</label>
                <input v-model.trim="password2" id="passwordInput2" :type="passwordType" required data-cy="password2" autocomplete="new-password" class="form-control">
            </div>
            <div class="mb-3 form-check">
                <input v-model="showPassword" type="checkbox" class="form-check-input" id="showPassCheck">
                <label class="form-check-label" for="showPassCheck">Show passwords</label>
            </div>
            <button type="submit" class="btn btn-primary">Sign Up</button>
            <div class="my-3 text-center">
                By clicking the “Sign Up” button, you agree to our
                <div>
                    <a target="_blank" :href="`${docServer}docs/legal/privacy-policy.html`">Privacy Policy</a>
                    and
                    <a target="_blank" :href="`${docServer}docs/legal/terms-of-use.html`">Terms of Service</a>.
                </div>
            </div>
        </form>
        <div v-if="success" class="mx-auto" style="max-width: 300px">
            <template v-if="!uid">
                <h4>You are almost there</h4>
                <div class="my-2">
                    Complete your registration by clicking the verification link we sent to: {{ this.email }}.
                </div>
                <div class="my-2">
                    If you can't find the email from us, check your Junk folder, please.
                </div>
                <div class="my-2">
                    Also, you can resend the link by clicking the button below.
                </div>
                <ResendEmail :email="email" />
            </template>
            <template v-if="uid">
                <h5 class="fw-bold">Your registration is complete</h5>
                <div>
                    You can now login into your account.
                </div>
                <div>
                    <router-link :to="{name: 'login'}" class="mt-3 d-inline-block fw-bold">
                        Login
                    </router-link>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import ResendEmail from '@/components/ResendEmail.vue'

export default {
    components: {
        ResendEmail
    },
    data() {
        return {
            showPassword: false,
            email: '',
            password1: '',
            password2: '',
            error: '',
            emailDisabled: false,
            success: false,            
        }
    },
    computed: {
        passwordType() {
            return this.showPassword ? 'text' : 'password'
        },
        uid() {
            return this.$route.query.uid //userInviteId
        },
        docServer() {
            return process.env.VUE_APP_DOC_SERVER
        }
    },
    methods: {
        register() {
            if (this.password1 !== this.password2) {
                this.error = "Passwords don't match"
                return
            }
            this.$store.dispatch("auth/REGISTER", {
                email: this.email,
                password: this.password1,
                password_confirm: this.password2,
                uid: this.uid
            })
            .then(success => {
                this.success = true
                this.$gtag.event('sign_up')
                qp('track', 'CompleteRegistration')
            })
            .catch(error => {                
                if (error.response.data 
                    && error.response.data.email
                    && error.response.data.email.toString().includes('address already exists')
                ) {
                    this.error = 'User with this email address already exists'
                }
                 else {
                    this.error = error.response.data;
                }                
            })
        }
    },
    mounted() {
        const uid = this.uid
        if (uid) {
            this.$store.dispatch("auth/getInvite", {uid})
            .then(({data, status}) => {
                this.email = data.email
                this.emailDisabled = true
            })
        }
    }

}
</script>

<style>

</style>
