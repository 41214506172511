<template>
    <div>
        <h2 class="text-center text-danger fw-bold">Danger Zone: account deletion</h2>
        <div class="mx-auto mt-5" style="max-width: 800px">
            <div v-if="!success">
                <div class="text-danger fw-bold my-2">
                    <div v-if="$store.state.auth.groupName === 'Owner'">
                        This will delete all your and your team data.
                        Also, this remove all team members accounts.
                    </div>
                    <div v-else>
                        You will lose access to the servce.
                    </div>
                    <div>
                        The action cannot be undone.
                    </div>
                </div>
                <form  class="mt-4" style="max-width: 300px" @submit.prevent="remove({password})">
                    <div v-if="error" class="alert alert-danger">
                        {{ error }}
                    </div>
                    <input type="text" autocomplete="username" style="display: none">
                    <div class="mb-3">
                        <label for="passwordInput" class="form-label">Enter your password</label>
                        <input
                            v-model.trim="password"
                            id="passwordInput"
                            :type="passwordType"
                            required
                            class="form-control"
                            autocomplete="current-password"
                        >
                    </div>
                    <div class="mb-3">
                        <label for="confirmInput" class="form-label">
                            Please, type <span class="fw-bold">remove</span> in the field below
                        </label>
                        <input
                            v-model.trim="confirm"
                            id="confirmInput"
                            required
                            class="form-control"
                        >
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="showPassword" type="checkbox" class="form-check-input" id="showPassCheck">
                        <label class="form-check-label" for="showPassCheck">Show my password</label>
                    </div>
                    <button type="submit" :disabled="loading || confirm.toLowerCase() !== 'remove'" class="btn btn-danger">
                        Delete
                            <template v-if="$store.state.auth.groupName === 'Owner'">
                                Team
                            </template>
                            <template v-else>
                                My
                            </template>
                            Account
                    </button>
                </form>
            </div>
            <div class="alert alert-success" style="max-width: 600px" v-if="success">
                <div class="mb-2">
                    The account was successfully deleted. You may close this page.
                </div>
                <div>
                    If you want to create another account in the future, you will be able to do so with the same email address.
                </div>
                <div>
                    Register a new owner account <router-link :to="{name: 'register'}">here</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('auth')

export default {
    data() {
        return {
            password: null,
            showPassword: false,
            success: false,
            error: null,
            loading: false,
            confirm: ''
        }
    },
    computed: {
        passwordType() {
            return this.showPassword ? 'text' : 'password'
        }
    },
    methods: {        
        ...mapActions(['removeTeamAccount', 'removeAccount']),
        remove(payload) {
            this.error = null
            this.loading = true
            const promise = this.$store.state.auth.groupName === 'Owner' ? this.removeTeamAccount(payload) : this.removeAccount(payload)
            // this.removeAccount(payload)
            promise
                .then((resp) => {
                    this.success = true
                    this.loading = false
                })
                .catch((err) => {                    
                    this.error = err.response.data?.detail ?? err.response.data
                    this.loading = false
                })
        }
    },
}
</script>
