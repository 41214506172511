<template>
    <div>
        <h4 class="text-center">Profile Settings</h4>
        <div class="d-flex flex-wrap">
            <ChangePassword />
            <ChangeEmail />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import ChangePassword from '@/components/ChangePassword.vue'
import ChangeEmail from '@/components/ChangeEmail.vue'

export default {
    components: {
      ChangePassword,
      ChangeEmail
    },
}
</script>
